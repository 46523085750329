import React, { useRef } from 'react'
import { gsap } from 'gsap'
import useIsomorphicLayoutEffect from '@/utils/useIsomorphicEffect'

interface FadeInProps {
  children: React.ReactNode
  duration?: number
  delay?: number
  x?: number
  y?: number
  ease?: string
}

const FadeIn = ({ children, duration, delay, x, y, ease }: FadeInProps) => {
  const el = useRef<HTMLDivElement>(null)

  useIsomorphicLayoutEffect(() => {
    gsap.to(el.current, {
      x: 0,
      y: 0,
      opacity: 1,
      delay: delay ?? 0,
      duration: duration ?? 0.5,
      ease: ease ?? 'power4.out',
    })
  }, [])

  return (
    <div
      style={{
        opacity: 0,
        transform: 'translate(' + (x ?? 0) + 'px, ' + (y ?? 0) + 'px)',
      }}
      ref={el}
    >
      {children}
    </div>
  )
}

export default FadeIn
