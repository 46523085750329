import React from 'react'
import { FaRegEnvelope, FaLine, FaPhoneAlt } from 'react-icons/fa'
import { FaArrowRight } from 'react-icons/fa6'
import { StyleContactSocial } from './style'

const ContactSocial = () => {
  return (
    <StyleContactSocial className='contact-address'>
      <div className='-mx-4 grid grid-cols-3 md:mx-0 md:divide-x-2'>
        <div className='contact-items'>
          <div className='flex h-full flex-col space-y-4 p-4'>
            <div className='mt-4 flex flex-col items-center justify-center'>
              <FaLine className='text-black' size={30} />
            </div>
            <div className='content text-center'>
              <p>@twinsynergy</p>
            </div>
            <div className='flex flex-none justify-end'>
              <a
                target='_blank'
                href='https://lin.ee/8ocMtzc'
                className='small inline-flex items-center text-black'
                rel='noopener noreferrer'
              >
                คลิกเลย <FaArrowRight className='ml-2' size={14} />
              </a>
            </div>
          </div>
        </div>
        <div className='contact-items'>
          <div className='flex h-full flex-col space-y-4 p-4'>
            <div className='mt-4 flex flex-col items-center justify-center'>
              <FaPhoneAlt className='text-black' size={30} />
            </div>
            <div className='content text-center'>
              <p>063-789-9059</p>
            </div>
            <div className='flex flex-none justify-end'>
              <a href='tel:063-789-9059' className='small inline-flex items-center text-black'>
                คลิกเลย <FaArrowRight className='ml-2' size={14} />
              </a>
            </div>
          </div>
        </div>
        <div className='contact-items'>
          <div className='flex h-full flex-col space-y-4 p-4'>
            <div className='mt-4 flex flex-col items-center justify-center'>
              <FaRegEnvelope className='text-black' size={30} />
            </div>
            <div className='content text-center'>
              <p>info@twinsynergy.co.th</p>
            </div>
          </div>
        </div>
      </div>
    </StyleContactSocial>
  )
}

export default ContactSocial
